import { API } from '@stoplight/elements';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import  styled  from 'styled-components';
const ApiDocs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation(); // Use useLocation hook to get the current URL
  const [verified, setVerified] = useState(false);
  const [apiDescriptionDocument, setApiDescriptionDocument] = useState();

  useEffect(() => {
    // Define a function to make the API call
    const fetchData = async () => { 
      const token = new URLSearchParams(location.search).get('token'); // Get token from URL
      window.history.pushState({ path: 'api/docs' }, '/api/docs');
      try {
        const headers = new Headers({
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        });
        const response = await fetch(`${process.env?.REACT_APP_API_URL}/api/client/docs.json`);
  
        if (!response.ok) {
          window.location.href = `/error?code=${response?.status}`; // Redirect to error page if API call fails
          console.error('Failed to fetch data');
        }
  
        const data = await response.json();
        // Process the data as needed
        setVerified(true);
        setApiDescriptionDocument(data);
        setIsLoading(false); // Set loading state to false after API call is complete
      } catch (error) {
        window.location.href = `/error?code=400`; // Redirect to error page if API call fails
        console.error('Failed to fetch data');
        setVerified(true);
        setIsLoading(false); // Set loading state to false if API call fails
      }
    };
    
    fetchData(); // Call fetchData function when the component mounts or when the URL changes
  }, []); // Remove dependency array to ensure the effect runs on component mount and URL change
  
  return (
    <>
      {verified ? (
        
          <API
            basePath="api/docs"
           apiDescriptionDocument={apiDescriptionDocument || {}}
          />
        
      ) : (
        <Wrapper>
          <div className="auth-container">
            <div className="main">
              {/* Logo */}
              <img
                src="https://auth-stage.occy.co.uk/7ea5h2ichrkntsdppr1eam0an4/20230213074239/assets/images/image.jpg"
                alt="Logo"
                className="logo"
              />
              <h3 className="heading">Client Documentation</h3>
              {/* Loader */}
              <div className="col-sm-6 text-center">
                <div className="loader1">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      )}
    </>
  );
};
const Wrapper = styled.div`

.heading {
  font-size: 16px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin: 20px 0px 10px 0px;
  width: 100%;
  color: #fff;
  padding: 12px 20px;
  height: 48px;
  background: #5e1b60;
  border-radius: 8px;
  border: none;
  font-family: system-ui;
}
.main {
  background-color: #f4f4f4;
  border-radius: 16px;
  width: 400px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #909090;
}

.logo {
  width: 200px !important; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px;
}

.loader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  opacity: 0.5;
  border: 3px solid #4a154b;
  -webkit-animation: loader 1s ease-in-out infinite alternate;
  animation: loader 1s ease-in-out infinite alternate;
}

.loader:before {
  content: ' ';
  position: absolute;
  z-index: -1;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border: 3px solid #4a154b;
}

.loader:after {
  content: ' ';
  position: absolute;
  z-index: -1;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 3px solid #4a154b;
}

@keyframes loader {
  from {
    transform: rotate(0deg) scale(1, 1);
    border-radius: 0px;
  }
  to {
    transform: rotate(360deg) scale(0, 0);
    border-radius: 50px;
  }
}
@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg) scale(1, 1);
    border-radius: 0px;
  }
  to {
    -webkit-transform: rotate(360deg) scale(0, 0);
    border-radius: 50px;
  }
}

.loader1 {
  display: inline-block;
  font-size: 0px;
  padding: 0px;
}
.loader1 span {
  vertical-align: middle;
  border-radius: 100%;

  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 3px 2px;
  -webkit-animation: loader1 0.8s linear infinite alternate;
  animation: loader1 0.8s linear infinite alternate;
}
.loader1 span:nth-child(1) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: #4a154b;
}
.loader1 span:nth-child(2) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: #4a154b;
}
.loader1 span:nth-child(3) {
  -webkit-animation-delay: -0.26666s;
  animation-delay: -0.26666s;
  background: #4a154b;
}
.loader1 span:nth-child(4) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: #4a154b;
}
.loader1 span:nth-child(5) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: #4a154b;
}

@keyframes loader1 {
  from {
    transform: scale(0, 0);
  }
  to {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes loader1 {
  from {
    -webkit-transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
  }
}

`;
export default ApiDocs;
