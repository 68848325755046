import { DevPortalProvider } from '@stoplight/elements-dev-portal';
import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { StoplightAPI } from './components/API';
import { Navigation } from './components/Navigation';
import ApiDocs from './components/ApiDocs';
import NotFound from './components/error';

class App extends Component {
  render() {
    const location=window.location;
    return (
      <DevPortalProvider>
        <BrowserRouter>
          <div className="app-container">
            {(!location?.pathname?.includes('error')) && <header>
              <Navigation />
            </header>}
            <main className="main-content">
              <Switch>
                <Route exact path="/">
                  <Redirect to="/api/docs" />
                </Route>
                <Route path="/zoom-api" component={StoplightAPI} />
                <Route path="/api/docs" component={ApiDocs} />
                <Route component={NotFound} />
              </Switch>
            </main>
          </div>
        </BrowserRouter>
      </DevPortalProvider>
    );
  }
}

export default App;
