import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import  styled  from 'styled-components';
const NotFound = () => {
  const location = useLocation(); // Use useLocation hook to get the current URL
	const code = new URLSearchParams(location.search).get('code');
const [msg, setMsg] = useState(`Not Found`);
const [descp,setDescp] = useState(`The requested resource could not be found. Subsequent requests by the client are permissible.`);

useEffect(() => {
if(code === '400') {
	setMsg(`Bad Request`);
}else if(code === '401') {
	setMsg('Unauthorized');
  setDescp('You don’t have access to this area of application. Speak to your administrator to unblock this feature.');
}
else if(code === '403') {
	setMsg('Forbidden');
}
}, [code]);
	
	return(
 
    <Wrapper>
   <div className="page-wrap">
  <div className="page-not-found text-center">
    {/* <img src="https://res.cloudinary.com/razeshzone/image/upload/v1588316204/house-key_yrqvxv.svg" className="img-key" alt=""/> */}
    <h1 className="text-xl">
      <span>{code?.substring(0, 1)}</span>
      <span>{code?.substring(1, 2)}</span>
      <span className="broken">{code?.substring(2, 3)}</span>
    </h1>
    <h4 className="text-md">{msg}</h4>
    <h4 className="text-sm text-sm-btm">{descp}</h4>
  </div>
</div>
</Wrapper>
  
	)
	};
const Wrapper = styled.div`
.page-wrap {
  padding: 30px 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .page-not-found {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    .img-key {
      margin-bottom: 0px;
    }
    h1.text-xl {
      color: #000;
      text-transform: uppercase;
      line-height: 50px;
      font-size: 160px;
      font-weight: 800;
      letter-spacing: -28px;
      text-shadow: -6px 4px 0px #fff;
      margin-left: -20px;
      margin-top: 50px;
      margin-bottom: 50px;
      span {
        transition: all 1s ease;
        display: inline-block;
        animation: pulse 5s infinite;
      }
    }
    h4.text-md,
    h4.text-sm {
      margin-top:10px;
      letter-spacing: 0.38px;
      font-weight: 300;
      line-height: 20px;
      font-size: 14px;
      text-transform: none;
      color: rgba(0, 0, 0, 0.5);
      margin-bottom: 0px;
      width: 100%;
      a {
        color: #1177bd;
        text-decoration: underline;
        font-weight: 700;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
    h4.text-md {
      font-size: 50px;
      font-weigth: 700;
      color: rgba(74, 21, 75, 1);;
      text-transform: none;
    }
    h4.text-sm-btm {
      top: auto;
      bottom: 40px;
    }
  }
}
@keyframes pulse {
  0% {
    color: rgba(236, 0, 140, 1);
  }
  50% {
    color: rgba(74, 21, 75, 1);
  }
  100% {
    color: rgba(236, 0, 140, 1);
  }
}
@media (max-width: 768px) {
  .page-wrap {
    .page-not-found {
      h1.text-xl {
        font-size: 120px;
        letter-spacing: -20px;
        margin-bottom: 30px;
      }
      h4.text-sm {
        top: 10px;
      }
      h4.text-sm-btm {
        bottom: -60px;
      }
      h4.text-md {
        font-size: 30px;
      }
    }
  }
}

`;
export default NotFound;
